import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainTemplate from '../components/templates/MainTemplate';
import MainNavigation from '../components/molecules/mainNavigation/MainNavigation';
import { RouteType } from '../model/Model';
import Footer from '../components/molecules/footer/Footer';
import React from 'react';
import GettingStarted from './GettingStarted';
import Enterprise from './Enterprise';
import Trainings from './Trainings';
import Docs from './Docs';
import Homepage from './Homepage';
import Privacy from './Privacy';
import { Helmet } from 'react-helmet';
import { RedisAndAxelote } from '../components/molecules/articles/RedisAndAxelote';
import { SampleTodoAppWithAxelote } from '../components/molecules/articles/SampleTodoAppWithAxelote';
import { AxeloteAdapter } from '../components/molecules/articles/AxeloteAdapter';
import { AxeloteProvider } from '../components/molecules/articles/AxeloteProvider';
import { AxeloteCliAndAxeloteJs } from '../components/molecules/articles/AxeloteCliAndAxeloteJs';
import { AxeloteQueryBuilder } from '../components/molecules/articles/AxeloteQueryBuilder';
import { AxeloteTransactions } from '../components/molecules/articles/AxeloteTransactions';
import { AxeloteRepositories } from '../components/molecules/articles/AxeloteRepositories';
import { AxeloteSecurityCombinedQueries } from '../components/molecules/articles/AxeloteSecurityCombinedQueries';
import { AxeloteSecurityContext } from '../components/molecules/articles/AxeloteSecurityContext';
import { AxeloteSecurityRules } from '../components/molecules/articles/AxeloteSecurityRules';
import { AxeloteExeptionsHandling } from '../components/molecules/articles/AxeloteExeptionsHandling';
import Agency from './Agency';
import DigitalProductPassport from './DigitalProductPassport';
import {isDPP} from "../utils/Utils";
import Maintenance from "./Maintenance";

interface Props {
  content?: JSX.Element;
  route: RouteType;
}

const PageSelector: React.FC<Props> = ({ route }) => {
  const isNotArticle: boolean = !route.includes('axelote');
  return (
    <>
      {/*<MainNavigation />*/}
        <>
            <main>
                {
                    {
                        [RouteType.HOMEPAGE]: <Maintenance />,
                    }[route]
                }
            </main>
            {/*<Footer extended={false} />*/}
        </>

      {/*{isDPP() ? (*/}
      {/*  <>*/}
      {/*    <main>*/}
      {/*      {*/}
      {/*        {*/}
      {/*          [RouteType.HOMEPAGE]: <DigitalProductPassport />,*/}
      {/*        }[route]*/}
      {/*      }*/}
      {/*    </main>*/}
      {/*    <Footer extended={false} />*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  <>*/}
      {/*    {isNotArticle && (*/}
      {/*      <main>*/}
      {/*        {*/}
      {/*          {*/}
      {/*            [RouteType.GETTING_STARTED]: <GettingStarted />,*/}
      {/*            [RouteType.AGENCY]: <Agency />,*/}
      {/*            [RouteType.TUTORIALS]: <Trainings />,*/}
      {/*            [RouteType.DOCUMENTATION]: <Docs />,*/}
      {/*            [RouteType.HOMEPAGE]: <Homepage />,*/}
      {/*            [RouteType.OFFER]: <Enterprise />,*/}
      {/*            [RouteType.PRIVACY]: <Privacy />,*/}
      {/*          }[route]*/}
      {/*        }*/}
      {/*      </main>*/}
      {/*    )}*/}

      {/*    {*/}
      {/*      {*/}
      {/*        [RouteType.ARTICLE_REDIS_AND_AXELOTE]: <RedisAndAxelote />,*/}
      {/*        [RouteType.ARTICLE_SAMPLE_TODO_APP_WITH_AXELOTE]: <SampleTodoAppWithAxelote />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_ADAPTER]: <AxeloteAdapter />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_PROVIDER]: <AxeloteProvider />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_AXELOTE_CLI_AND_AXELOTE_JS]: <AxeloteCliAndAxeloteJs />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_QUERY_BUILDER]: <AxeloteQueryBuilder />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_REPOSITORIES]: <AxeloteRepositories />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_TRANSACTIONS]: <AxeloteTransactions />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_SECURITY_COMBINED_QUERIES]: <AxeloteSecurityCombinedQueries />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_SECURITY_CONTEXT]: <AxeloteSecurityContext />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_SECURITY_RULES]: <AxeloteSecurityRules />,*/}
      {/*        [RouteType.ARTICLE_AXELOTE_EXEPTION_HANDLING]: <AxeloteExeptionsHandling />,*/}
      {/*      }[route]*/}
      {/*    }*/}

      {/*    <Footer extended={route === RouteType.DOCUMENTATION} />*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  );
};

const App: React.FC = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title>Axelote</title>
      </Helmet>
      <BrowserRouter>
        <Routes location={location} key={location.pathname}>
          {(Object.values(RouteType) as RouteType[]).map((route) => {
            return <Route key={route} path={'/' + route} element={<PageSelector route={route} />} />;
          })}
        </Routes>
      </BrowserRouter>
    </MainTemplate>
  );
};

export default App;
