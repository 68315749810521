import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import icon from '../../../public/assets/icon.png';


interface Props {
  white?: boolean;
}

const StyledDiv = styled.div`
    display: flex;
  
    img {
      width: 32px;
      height: auto;
      margin-right: 12px;
    }
  
`;

const StyledName = styled(NavLink)<{}>`
  color: #40434a;
  font-size: ${(props) => props.theme.size.l};
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  margin-right: 12rem;
`;

const AxeloteLogo: React.FC<Props> = ({ white = false }) => {
  return (<StyledDiv>
        <img src={icon}/>
        <StyledName to={'/'}>axelote</StyledName>
  </StyledDiv>
  );
};

export default AxeloteLogo;
