export interface Test {
  test: string;
}
export interface ContextType {
  activeCodeTheme: ThemeType;
  changeCodeTheme: (arg: ThemeType) => void;
  isMobile: boolean;
}
export interface ArticleElement {
  href: string;
  description: string;
  alt: string;
  created: string;
  title: string;
  image: string;
}
export enum ThemeType {
  GRAY = 'GRAY',
  COLOR = 'COLOR',
  HIGH_CONTRAST = 'HIGH_CONTRAST',
}

export enum RouteType {
  DIGITAL_PRODUCT_PASSPORT_DOCS = 'digital-product-passport-docs',
  AXELOTE = 'axelote',
  AXELOTE_DOCS = 'axelote-docs',
  CONTENT_REPOSITORY_DOCS = 'content-repository-docs',
  CONTENT_REPOSITORY = 'content-repository',
  CONTENT_SECURED = 'content-secured',
  DIGITAL_PRODUCT_PASSPORT = 'digital-product-passport',
  HOMEPAGE = '',
  GETTING_STARTED = 'getting-started',
  DOCUMENTATION = 'docs',
  PRODUCTS = 'products',
  SERVICES = 'services',
  ABOUT_US = 'about_us',
  OFFER = 'offer',
  TUTORIALS = 'tutorials',
  PRIVACY = 'privacy',
  AGENCY = 'agency',
  // articles
  ARTICLE_REDIS_AND_AXELOTE = 'tutorials/axelote-pre-requirements-redis-set-up',
  ARTICLE_AXELOTE_PROVIDER = 'tutorials/axelote-provider-set-up',
  ARTICLE_AXELOTE_ADAPTER = 'tutorials/integrating-axelote-adapter-with-spring-boot-application',
  ARTICLE_AXELOTE_AXELOTE_CLI_AND_AXELOTE_JS = 'tutorials/integrating-frontend-app-with-axelote',
  ARTICLE_SAMPLE_TODO_APP_WITH_AXELOTE = 'tutorials/creating-todo-list-using-react-and-axelote',
  ARTICLE_AXELOTE_QUERY_BUILDER = 'tutorials/axelote-js-query-builder',
  ARTICLE_AXELOTE_REPOSITORIES = 'tutorials/axelote-js-repositories',
  ARTICLE_AXELOTE_TRANSACTIONS = 'tutorials/axelote-js-transactions',
  ARTICLE_AXELOTE_SECURITY_COMBINED_QUERIES = 'tutorials/axelote-cli-securing-combined-queries',
  ARTICLE_AXELOTE_SECURITY_CONTEXT = 'tutorials/axelote-adapter-security-context',
  ARTICLE_AXELOTE_SECURITY_RULES = 'tutorials/axelote-adapter-security-rules',
  ARTICLE_AXELOTE_EXEPTION_HANDLING = 'tutorials/axelote-adapter-exceptions-handling',
}
