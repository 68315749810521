import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import AxeloteLogo from "../components/atoms/AxeloteLogo";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;

    section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 200;
        height: 600px;
        
        p {
            margin-top: 16px;
            font-size: 18px;
        }
       
        a {
            margin: 0;
        }
    }
    
`;

const Homepage: React.FC = () => {
  return (
    <StyledContainer>
      <Helmet>
        <link rel="canonical" href="https://axelote.com" />
      </Helmet>
      <section className="flex">
          <AxeloteLogo />
          <p>We’re Improving Our Products</p>
      </section>
    </StyledContainer>
  );
};

export default Homepage;
